(function () {
    'use strict';
    
    /**
     * hasOwnProperty.
     */

    var has = Object.prototype.hasOwnProperty;

    /**
     * Require the given path.
     *
     * @param {String} path
     * @return {Object} exports
     * @api public
     */

    function require(path, parent, orig) {
        var resolved = require.resolve(path);

        // lookup failed
        if (null == resolved) {
            orig = orig || path;
            parent = parent || 'root';
            var err = new Error('Failed to require "' + orig + '" from "' + parent + '"');
            err.path = orig;
            err.parent = parent;
            err.require = true;
            throw err;
        }

        var module = require.modules[resolved];

        // perform real require()
        // by invoking the module's
        // registered function
        if (!module.exports) {
            module.exports = {};
            module.client = module.component = true;
            module.call(this, module.exports, require.relative(resolved), module);
        }

        return module.exports;
    }

    /**
     * Registered modules.
     */

    require.modules = {};

    /**
     * Registered aliases.
     */

    require.aliases = {};

    /**
     * Resolve `path`.
     *
     * Lookup:
     *
     *   - PATH/index.js
     *   - PATH.js
     *   - PATH
     *
     * @param {String} path
     * @return {String} path or null
     * @api private
     */

    require.resolve = function (path) {
        var index = path + '/index.js';

        var paths = [
            path,
            path + '.js',
            path + '.json',
            path + '/index.js',
            path + '/index.json'
        ];

        for (var i = 0; i < paths.length; i++) {
            var path = paths[i];
            if (has.call(require.modules, path)) return path;
        }

        if (has.call(require.aliases, index)) {
            return require.aliases[index];
        }
    };

    /**
     * Normalize `path` relative to the current path.
     *
     * @param {String} curr
     * @param {String} path
     * @return {String}
     * @api private
     */

    require.normalize = function (curr, path) {
        var segs = [];

        if ('.' != path.charAt(0)) return path;

        curr = curr.split('/');
        path = path.split('/');

        for (var i = 0; i < path.length; ++i) {
            if ('..' == path[i]) {
                curr.pop();
            } else if ('.' != path[i] && '' != path[i]) {
                segs.push(path[i]);
            }
        }

        return curr.concat(segs).join('/');
    };

    /**
     * Register module at `path` with callback `definition`.
     *
     * @param {String} path
     * @param {Function} definition
     * @api private
     */

    require.register = function (path, definition) {
        require.modules[path] = definition;
    };

    /**
     * Alias a module definition.
     *
     * @param {String} from
     * @param {String} to
     * @api private
     */

    require.alias = function (from, to) {
        if (!has.call(require.modules, from)) {
            throw new Error('Failed to alias "' + from + '", it does not exist');
        }
        require.aliases[to] = from;
    };

    /**
     * Return a require function relative to the `parent` path.
     *
     * @param {String} parent
     * @return {Function}
     * @api private
     */

    require.relative = function (parent) {
        var p = require.normalize(parent, '..');

        /**
         * lastIndexOf helper.
         */

        function lastIndexOf(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i] === obj) return i;
            }
            return -1;
        }

        /**
         * The relative require() itself.
         */

        function localRequire(path) {
            var resolved = localRequire.resolve(path);
            return require(resolved, parent, path);
        }

        /**
         * Resolve relative to the parent.
         */

        localRequire.resolve = function (path) {
            // resolve deps by returning
            // the dep in the nearest "deps"
            // directory
            if ('.' != path.charAt(0)) {
                var segs = parent.split('/');
                var i = lastIndexOf(segs, 'deps') + 1;
                if (!i) i = 0;
                path = segs.slice(0, i + 1).join('/') + '/deps/' + path;
                return path;
            }
            return require.normalize(p, path);
        };

        /**
         * Check if module is defined at `path`.
         */

        localRequire.exists = function (path) {
            return has.call(require.modules, localRequire.resolve(path));
        };

        return localRequire;
    };
    require.register("component-domify/index.js", Function("exports, require, module",
        "\n/**\n * Expose `parse`.\n */\n\nmodule.exports = parse;\n\n/**\n * Wrap map from jquery.\n */\n\nvar map = {\n  option: [1, '<select multiple=\"multiple\">', '</select>'],\n  optgroup: [1, '<select multiple=\"multiple\">', '</select>'],\n  legend: [1, '<fieldset>', '</fieldset>'],\n  thead: [1, '<table>', '</table>'],\n  tbody: [1, '<table>', '</table>'],\n  tfoot: [1, '<table>', '</table>'],\n  colgroup: [1, '<table>', '</table>'],\n  caption: [1, '<table>', '</table>'],\n  tr: [2, '<table><tbody>', '</tbody></table>'],\n  td: [3, '<table><tbody><tr>', '</tr></tbody></table>'],\n  th: [3, '<table><tbody><tr>', '</tr></tbody></table>'],\n  col: [2, '<table><tbody></tbody><colgroup>', '</colgroup></table>'],\n  _default: [0, '', '']\n};\n\n/**\n * Parse `html` and return the children.\n *\n * @param {String} html\n * @return {Array}\n * @api private\n */\n\nfunction parse(html) {\n  if ('string' != typeof html) throw new TypeError('String expected');\n  \n  // tag name\n  var m = /<([\\w:]+)/.exec(html);\n  if (!m) throw new Error('No elements were generated.');\n  var tag = m[1];\n  \n  // body support\n  if (tag == 'body') {\n    var el = document.createElement('html');\n    el.innerHTML = html;\n    return [el.removeChild(el.lastChild)];\n  }\n  \n  // wrap map\n  var wrap = map[tag] || map._default;\n  var depth = wrap[0];\n  var prefix = wrap[1];\n  var suffix = wrap[2];\n  var el = document.createElement('div');\n  el.innerHTML = prefix + html + suffix;\n  while (depth--) el = el.lastChild;\n\n  return orphan(el.children);\n}\n\n/**\n * Orphan `els` and return an array.\n *\n * @param {NodeList} els\n * @return {Array}\n * @api private\n */\n\nfunction orphan(els) {\n  var ret = [];\n\n  while (els.length) {\n    ret.push(els[0].parentNode.removeChild(els[0]));\n  }\n\n  return ret;\n}"
    ));
    require.register("minimal-counter/index.js", Function("exports, require, module",
        "\n/**\n * Module dependencies\n */\n\nvar domify = require( 'domify' );\n\n/**\n * Expose `MinimalCounter`\n */\n\nmodule.exports = MinimalCounter;\n\n/**\n * \n */\n\nfunction MinimalCounter( value ){\n\n  var self   = this;\n  this.el    = domify( '<div class=\"minimal-counter\"/>' )[0];\n  this.value = value || 100;\n  this.value.toString().split('').forEach( self.addDigit.bind( this ) );\n  this.update( this.value );\n\n}\n\nMinimalCounter.prototype.addDigit = function() {\n\n  var digit    = domify( '<div class=\"digit\"/>' )[0],\n      sequence = domify( '<div class=\"sequence\">'\n                        + [9, 8, 7, 6, 5, 4, 3, 2, 1, 0].join('\\n')\n                        + '</div>' )[0];\n  digit.appendChild( sequence );\n  this.el.appendChild( digit );\n};\n\nMinimalCounter.prototype.update = function( number ) {\n\n  var self          = this,\n      digits        = number.toString().split('').reverse(),\n      digitElements = this.el.querySelectorAll( '.sequence' ),\n      diff          = digitElements.length - digits.length;\n  \n  if( diff < 0 ){\n    while ( diff++ ){ this.addDigit(); }\n    digitElements = this.el.querySelectorAll( '.sequence' );\n  } else {\n    while ( diff-- ){ digits.push( -1 ); }\n  }\n\n  digits.forEach( function( num, index ){\n\n    var shift     = - ( 9 - parseInt( num, 10 ) ) * 10,\n        elIndex   = digitElements.length - index - 1,\n        element   = digitElements[elIndex],\n        translate = \"translate3d(0, \" + shift + \"%, 0)\";\n    \n    if( num === -1 ){\n      element.classList.add( 'is-hidden' );\n    } else {\n      element.classList.remove( 'is-hidden' );\n      self.setTransform( element, translate );\n    }\n  } );\n};\n\nvar prefix = ['webkitTransform', 'mozTransform', 'msTransform', 'oTransform', 'transform'];\n\nMinimalCounter.prototype.setTransform = function( element, translate ) {\n  prefix.forEach( function( transform ){\n    element.style[transform] = translate;\n  } );\n};"
    ));
    require.alias("component-domify/index.js", "minimal-counter/deps/domify/index.js");

    window.MinimalCounter = require('minimal-counter');
}).call();