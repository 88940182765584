'use strict';

/**
 * Initialize main helper object
 */
var PZTJS = {
    is_safari    : /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    is_firefox   : navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
    is_chrome    : /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
    is_ie10      : navigator.appVersion.indexOf('MSIE 10') !== -1,
    transitionEnd: 'transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',
    animIteration: 'animationiteration webkitAnimationIteration oAnimationIteration MSAnimationIteration',
    animationEnd : 'animationend webkitAnimationEnd'
};


/**
 * RequestAnimationFrame raw wrapper
 */
PZTJS.RAFit = function (callback) {
    var raf = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        function(callback){ window.setTimeout(callback, 1000/60) }; // IE Fallback

    var new_callback = function() {
        callback();
        raf(new_callback);
    };

    new_callback();
};


/**
 * RequestAnimationFrame for scrolling-related callbacks
 */
PZTJS.scrollRAF = function(callback) {
    var lastScrollPos = -1;

    var new_callback = function() {
        if (lastScrollPos !== window.pageYOffset) { // avoid calculations if not needed
            lastScrollPos = window.pageYOffset;
            callback();
        }
    };

    PZTJS.RAFit(new_callback);
};


/**
 * Detects whether user is viewing site from a mobile device
 * @param agent
 * @returns {boolean}
 */
PZTJS.isMobile = function (agent) {
    agent = agent || navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(agent);
};


/**
 * Trigger document ready event on window element (shorthand)
 */
jQuery(document).ready(function () {
    jQuery(window).trigger('docready');
});


/**
 * Checks if element is visible in current viewport
 * @param el
 * @returns {boolean}
 */
PZTJS.isElementInViewport = function(el) {
    // special bonus for those using jQuery
    if (typeof jQuery === 'function' && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};